<template>
  <div class="livestream">
    <ModalShare @registeredModal="modalShare = $event" />
    <div class="container">
      <div class="primary">
        <div class="player">
          <img src="/storage/images/dummy-livestream.jpg" class="img-fluid" />
        </div>

        <div class="schedule">
          <TitleSection title="JADWAL PEKAN INI" size="medium" />
          <h2 class="title-date">{{ $moment().format("MMMM Y") }}</h2>
          <div class="date-wrapper">
            <div
              class="box"
              v-for="(date, index) in $store.state.live.schedule.items"
              :key="index + '-date'"
              :class="{ active: activeDate === Object.keys(date)[0] }"
              @click="
                (activeDate = Object.keys(date)[0]),
                  getSchedule(Object.keys(date)[0])
              "
            >
              <div class="date">
                {{ $moment(Object.keys(date)[0]).format("DD") }}
              </div>
              <div class="day">
                {{ $moment(Object.keys(date)[0]).format("dddd") }}
              </div>
            </div>
          </div>
          <div
            class="detail-schedule"
            :class="{ empty: schedules.length === 0 }"
          >
            <transition name="fade">
              <div
                class="list-livestream"
                v-if="schedules.length > 0"
                key="save"
              >
                <div
                  class="schedules"
                  v-for="(data, index) in schedules"
                  :key="index + '-schedule'"
                >
                  <Link :to="`/live/${data.slug}`">
                    <div class="secondary">
                      <div class="time">
                        {{
                          $moment
                            .unix(Math.floor(+data.startTime / 1000.0))
                            .utc()
                            .format("HH:mm")
                        }}
                      </div>
                      <div class="channel">{{ data.channel.title }}</div>
                      <div class="title">
                        {{ data.title }}
                      </div>
                      <!-- <div
                        class="desc"
                        v-html="data.description.substring(0, 150)"
                      >
                        {{ data.description.length >= 150 ? "..." : "" }}
                      </div> -->
                    </div>
                  </Link>
                  <!-- && +data.endTime <= new Date().valueOf() -->
                  <div
                    class="primary"
                    v-if="checkRange(data.startTime, data.endTime)"
                  >
                    <Link :to="`/live/${data.slug}`" class="playing"
                      >SEDANG TAYANG</Link
                    >
                  </div>
                </div>
              </div>
              <div class="no-schedule" v-else key="save_">
                <Icons name="no-schedule" />
                <p class="title-no-schedule">
                  Tidak ada jadwal live streaming di tanggal ini
                </p>
                <p class="desc-no-schedule">
                  Silakan cek tanggal lainnya untuk program live streaming
                  <br class="for-mobile" />yang bisa kamu saksikan
                </p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="secondary">
        <div class="live-chat">
          <div class="button-wrapper">
            <div class="box">
              <Icons name="live-chat-disable" />
              <p class="text">LIVE CHAT</p>
            </div>
            <div class="box">
              <Icons name="polling-disable" />
              <p class="text">POLLING</p>
            </div>
          </div>
          <div class="list-chat">
            <Icons name="live-chat-closed" />
            <p class="title">Fitur Livechat Ditutup</p>
            <p class="desc">
              Anda bisa menggunakan fitur livechat di acara berikutnya ketika
              acara livestream dimulai.
            </p>
          </div>
        </div>

        <div class="playlist-video__populer">
          <div class="header">
            <TitleSection
              title="STREAMING SEBELUMNYA"
              size="medium"
              class="header__title"
            />
          </div>
          <div class="body">
            <transition-group name="list" tag="div" class="list-video-populer">
              <ShimmerCardHomeLandscapeSmall
                v-for="(item, idx) in 5"
                :key="`vod-${idx}`"
                v-show="$store.state.live.vod.loading"
              />
              <CardEight
                v-show="!$store.state.live.vod.loading"
                v-for="item in $store.state.live.vod.items"
                :key="item.id"
                :to="`/live/${item.slug}`"
                :image="
                  item.thumbnail
                    ? item.thumbnail
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :duration="`Tayang ${$moment(+item.startDate)
                  .local()
                  .format('DD MMM YYYY')}`"
                :category="item.interest"
                :channel="item.channel"
                :title="item.title"
                :isAdvertorial="item.isAdvertorial"
                :isText="item.isText"
                class="card-video-populer"
              />
            </transition-group>
            <div class="button-show-more">
              <span
                v-if="
                  live.items.length < live.totalItem && !live.loadingShowMore
                "
                @click="nextPopuler"
                >{{
                  $store.state.live.vod.loadingShowMore
                    ? "LOADING"
                    : "LIHAT LEBIH BANYAK"
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- START ADS -->
    <LivestreamAds />
    <!-- END ADS -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import ModalShare from "/components/modal/Share";
import CardEight from "/components/cards/CardEight.vue";
import LivestreamAds from "/components/ads/livestream/LivestreamAds.vue";
import TitleSection from "@/components/TitleSection";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ShimmerCardHomeLandscapeSmall from "@/components/shimmer/CardHomeLandscapeSmall";

export default {
  async asyncData({ error, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Live",
        desc: "Ruang event online dan livestreaming di narasi.tv",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerCardHomeLandscapeSmall,
    Icons,
    Link,
    TitleSection,
    ModalShare,
    CardEight,
    LivestreamAds,
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      live: (state) => {
        return state.live.vod;
      },
    }),
  },
  data() {
    return {
      limit: 3,
      activeDate: this.$moment().format("YYYY-MM-DD"),
      dates: [],
      schedules: [],
    };
  },
  methods: {
    checkRange(startTime, endTime) {
      const compareDate = this.$moment().local();
      const startDateMoment = this.$moment
        .unix(Math.floor(startTime / 1000.0))
        .local();
      const endDateMoment = this.$moment
        .unix(Math.floor(endTime / 1000.0))
        .local();
      return compareDate.isBetween(startDateMoment, endDateMoment);
    },
    async initData() {
      await this.$store.dispatch("live/getVodLiveStreaming", {
        limit: this.limit,
      });

      await this.$store.dispatch("live/getScheduleLiveStreaming").then(() => {
        this.getSchedule(this.$moment().format("YYYY-MM-DD"));
      });
    },
    nextPopuler() {
      this.limit = this.limit + 3;
      this.$store.dispatch("live/getVodLiveStreaming", {
        limit: this.limit,
        loadingShowMore: true,
      });
    },
    groupBy(data, property) {
      return data.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    getSchedule(param) {
      let data = [];
      this.$store.state.live.schedule.items.map((item) => {
        if (param == Object.keys(item)[0]) {
          data = item[Object.keys(item)[0]];
        }
      });

      this.schedules = data;
    },
    getTime(param) {
      return this.$moment.unix(param).format("HH:mm");
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.livestream {
  padding: 32px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }
    .primary {
      width: 65%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .player {
        background: #616161;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1024px) {
          height: 220px;
        }
        svg {
          margin-bottom: 32px;
          @media only screen and (max-width: 1024px) {
            width: 60px;
            height: 60px;
            margin-bottom: 24px;
          }
        }
        .title {
          color: #fafafa;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 4px;
          }
        }
        .desc {
          color: #e0e0e0;
          font-size: 16px;
          margin: 0;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
      .schedule {
        background: #fafafa;
        padding: 16px 16px 24px;
        .title {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }
        .title-date {
          font-size: 16px;
          font-weight: 500;
          color: #0c0b0d;
          margin-bottom: 10px;
        }
        .date-wrapper {
          display: flex;
          border-bottom: 1px solid lightgray;
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            overflow-x: auto;
          }
          .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px;
            cursor: pointer;
            border-bottom: 1px solid transparent;
            &.active {
              border-color: #4a25aa;
              .date {
                color: #4a25aa;
              }
              .day {
                color: #4a25aa;
              }
            }
            .date {
              font-size: 16px;
              font-weight: 500;
              color: #616161;
            }
            .day {
              font-size: 12px;
              color: #757575;
            }
          }
        }
        .detail-schedule {
          &.empty {
            padding: 32px 0;
          }
          .fade-enter-active {
            transition: opacity 0.5s;
          }
          .fade-enter /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
          }
          .list-livestream {
            .schedules {
              display: flex;
              border-bottom: 1px solid lightgray;
              padding: 16px 0;
              @media only screen and (max-width: 1024px) {
                flex-direction: column;
              }
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                border: none;
                padding-bottom: 0;
              }
              .primary {
                width: 20%;
                position: relative;
                @media only screen and (max-width: 1024px) {
                  width: 100%;
                  margin-bottom: 8px;
                }
                .time {
                  font-size: 12px;
                  font-weight: 500;
                  color: #757575;
                }
                a.playing {
                  position: relative;
                  background: linear-gradient(
                    264.64deg,
                    #442da2 55.88%,
                    #783179 95.71%
                  );
                  padding: 8px 6px;
                  text-align: center;
                  height: 32px;
                  width: 118px;
                  border-radius: 4px;
                  font-size: 10px;
                  font-weight: 700;
                  color: #ffffff;
                  display: inline-block;
                  &::after {
                    position: absolute;
                    margin-top: 3px;
                    margin-left: 3px;
                    background-image: url("../../assets/img/arrow-right.png");
                    background-repeat: no-repeat;
                    background-size: 6px;
                    display: inline-block;
                    width: 10px;
                    height: 20px;
                    content: "";
                  }
                  &:hover {
                    color: #ffffff !important;
                  }
                }
              }
              a {
                width: 100%;
                .secondary {
                  width: 80%;
                  @media only screen and (max-width: 1024px) {
                    width: 100%;
                  }
                  .channel {
                    font-size: 12px;
                    font-weight: 500;
                    color: #4a25aa;
                    margin-bottom: 8px;
                    @media only screen and (max-width: 1024px) {
                      margin-bottom: 4px;
                    }
                  }
                  .title {
                    font-size: 16px;
                    font-weight: 500;
                    color: #0c0b0d;
                    margin-bottom: 8px;
                  }
                  .desc {
                    font-size: 14px;
                    color: #757575;
                    :deep(p) {
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
          .no-schedule {
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
              width: 35px;
              height: auto;
              margin-bottom: 16px;
            }
            .title-no-schedule {
              font-size: 14px;
              font-weight: 500;
              color: #0c0b0d;
              margin-bottom: 8px;
              text-align: center;
            }
            .desc-no-schedule {
              font-size: 12px;
              color: #333333;
              text-align: center;
              margin: 0;
              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .secondary {
      width: 32%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .live-chat {
        @media only screen and (max-width: 1024px) {
          display: none;
        }
        .button-wrapper {
          display: flex;
          justify-content: space-between;
          background: #fafafa;
          margin-bottom: 8px;
          .box {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 0;
            cursor: pointer;
            svg {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
            .text {
              margin: 0;
              font-size: 16px;
              font-weight: 700;
              color: #9e9e9e;
            }
          }
        }
        .list-chat {
          background: #fafafa;
          padding: 120px 50px;
          text-align: center;
          svg {
            margin-bottom: 16px;
          }
          .title {
            font-size: 14px;
            font-weight: 500;
            color: #0c0b0d;
            margin-bottom: 8px;
          }
          .desc {
            font-size: 12px;
            color: #616161;
            margin: 0;
          }
        }
      }

      .playlist-video {
        width: 30%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding-top: 24px;
        }
        &__populer {
          margin-top: 32px;
          @media only screen and (max-width: 1024px) {
            padding: 0 20px;
          }
          .header {
            margin-bottom: 24px;
          }
          .body {
            .list-enter-active {
              transition: all 1s;
            }
            .list-enter /* .list-leave-active below version 2.1.8 */ {
              opacity: 0;
            }
            .list-video-populer {
              margin-bottom: 24px;
              :deep(.card-eight) {
                &:first-child {
                  padding-top: 0;
                }
              }
              .card-video-populer {
                width: 100%;
                &:nth-child(6) {
                  padding-top: 0;
                }
              }
            }
            .button-show-more {
              text-align: center;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
