<template>
  <div class="livestream">
    <ModalShare @registeredModal="modalShare = $event" />
    <ModalPermission
      :data="detail"
      @registeredModal="modalPermission = $event"
    />
    <client-only>
      <ModalLiveChat :data="detail" @registeredModal="modalLiveChat = $event" />
      <ModalLivePolling
        :data="detail"
        @registeredModal="modalLivePolling = $event"
      />
    </client-only>
    <div
      class="container"
      :style="noAccess ? 'filter: blur(8px); -webkit-filter: blur(8px);' : ''"
    >
      <div class="primary">
        <client-only>
          <div class="player">
            <img
              v-if="
                noAccess ||
                (detail.mediaIdPlayer.length === 0 && !detail.embedUrl)
              "
              :src="detail.thumbnail"
              class="img-fluid"
            />
            <div
              v-else
              class="ratio ratio-16x9"
              ref="videoPlayer"
              id="video-player"
            ></div>
          </div>
        </client-only>
        <client-only>
          <div class="video-description">
            <div class="video-category">{{ detail.channel.title }}</div>
            <h1 class="video-title">{{ detail.title }}</h1>
            <p class="video-caption" v-html="detail.description"></p>
            <div class="share-wrapper" @click="modalShare.show()">
              <Icons name="bagikan" class="icon" color="#E0E0E0" />
              <p class="text">Bagikan</p>
            </div>
          </div>
        </client-only>
        <div class="chat-polling">
          <div class="d-flex justify-content-between align-items-center">
            <div class="chat" @click="modalLiveChat.show()">
              <em class="material-icons mx-2 text-white">question_answer</em>
              <span class="text-white fw-bold fs-14 btn-live-chat"
                >LIVE CHAT</span
              >
            </div>
            <div class="polling" @click="modalLivePolling.show()">
              <em class="material-icons mx-2 text-white">insert_chart</em>
              <span class="text-white fw-bold fs-14 btn-live-chat"
                >POLLING</span
              >
            </div>
          </div>
        </div>
        <div class="schedule">
          <TitleSection title="JADWAL PEKAN INI" size="medium" />
          <h2 class="title-date">{{ $moment().format("MMMM Y") }}</h2>
          <div class="date-wrapper">
            <div
              class="box"
              v-for="(date, index) in $store.state.live.schedule.items"
              :key="index + '-date'"
              :class="{ active: activeDate === Object.keys(date)[0] }"
              @click="
                (activeDate = Object.keys(date)[0]),
                  getSchedule(Object.keys(date)[0])
              "
            >
              <div class="date">
                {{ $moment(Object.keys(date)[0]).format("DD") }}
              </div>
              <div class="day">
                {{ $moment(Object.keys(date)[0]).format("dddd") }}
              </div>
            </div>
          </div>
          <div
            class="detail-schedule"
            :class="{ empty: schedules.length === 0 }"
          >
            <transition name="fade">
              <div
                class="list-livestream"
                v-if="schedules.length > 0"
                key="save"
              >
                <div
                  class="schedules"
                  v-for="(data, index) in schedules"
                  :key="index + '-schedule'"
                >
                  <div class="secondary">
                    <div class="time">
                      {{
                        $moment
                          .unix(Math.floor(+data.startTime / 1000.0))
                          .utc()
                          .format("HH:mm")
                      }}
                      WIB
                    </div>
                    <div class="channel">{{ data.channel.title }}</div>
                    <div class="title">
                      {{ data.title }}
                    </div>
                    <!-- <div
                      class="desc"
                      v-html="data.description.substring(0, 150)"
                    >
                      {{ data.description.length >= 150 ? "..." : "" }}
                    </div> -->
                  </div>
                  <!-- && +data.endTime <= new Date().valueOf() -->
                  <div
                    class="primary"
                    v-if="checkRange(data.startTime, data.endTime)"
                  >
                    <Link
                      v-if="data.slug === $route.params.slug"
                      :to="`/live/${data.slug}`"
                      class="playing disabled"
                      >SEDANG DILIHAT</Link
                    >
                    <Link v-else :to="`/live/${data.slug}`" class="playing"
                      >LIHAT TAYANGAN</Link
                    >
                  </div>
                </div>
              </div>
              <div class="no-schedule" v-else key="save_">
                <Icons name="no-schedule" />
                <p class="title-no-schedule">
                  Tidak ada jadwal live streaming di tanggal ini
                </p>
                <p class="desc-no-schedule">
                  Silakan cek tanggal lainnya untuk program live streaming
                  <br class="for-mobile" />yang bisa kamu saksikan
                </p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="secondary">
        <div class="live-chat">
          <div class="button-wrapper">
            <div class="box" @click="tabActive = 'LiveChatting'">
              <Icons
                :name="
                  tabActive === 'LiveChatting'
                    ? 'live-chat-enable'
                    : 'live-chat-disable'
                "
              />
              <p
                :class="tabActive === 'LiveChatting' ? 'success' : ''"
                class="text"
              >
                LIVE CHAT
              </p>
            </div>
            <div class="box" @click="tabActive = 'LivePolling'">
              <Icons
                :name="
                  tabActive === 'LivePolling'
                    ? 'polling-enable'
                    : 'polling-disable'
                "
              />
              <p
                :class="tabActive === 'LivePolling' ? 'success' : ''"
                class="text"
              >
                POLLING
              </p>
            </div>
          </div>
          <LiveChatting
            v-if="tabActive === 'LiveChatting'"
            :detail="detail"
            :referencyId="$route.params.slug"
          />
          <LivePolling
            v-else
            :detail="detail"
            :referencyId="$route.params.slug"
          />
        </div>
        <div
          class="playlist-video__populer"
          v-if="
            $store.state.live.live.loading ||
            $store.state.live.live.items.length > 1
          "
        >
          <div class="header">
            <TitleSection
              title="STREAMING LAINNYA"
              size="medium"
              class="header__title"
            />
          </div>
          <div class="body">
            <transition-group name="list" tag="div" class="list-video-populer">
              <ShimmerCardHomeLandscapeSmall
                v-for="(item, idx) in 5"
                :key="`live-${idx}`"
                v-show="$store.state.live.live.loading"
              />
              <CardEight
                v-show="!$store.state.live.live.loading"
                v-for="item in $store.state.live.live.items"
                :key="item.id"
                :to="`/live/${item.slug}`"
                :time="{
                  startTime: item.startDate,
                  endTime: item.endDate,
                }"
                :isAdvertorial="item.isAdvertorial"
                :isText="item.isText"
                :image="
                  item.thumbnail
                    ? item.thumbnail
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :duration="'LIVE'"
                :category="item.interest"
                :channel="item.channel"
                :title="item.title"
                class="card-video-populer"
              />
            </transition-group>
          </div>
        </div>
        <div class="playlist-video__populer">
          <div class="header">
            <TitleSection
              title="STREAMING SEBELUMNYA"
              size="medium"
              class="header__title"
            />
          </div>
          <div class="body">
            <transition-group name="list" tag="div" class="list-video-populer">
              <ShimmerCardHomeLandscapeSmall
                v-for="(item, idx) in 5"
                :key="`vod-${idx}`"
                v-show="$store.state.live.vod.loading"
              />
              <CardEight
                v-show="!$store.state.live.vod.loading"
                v-for="item in $store.state.live.vod.items"
                :key="item.id"
                :to="`/live/${item.slug}`"
                :image="
                  item.thumbnail
                    ? item.thumbnail
                    : 'https://narasi.tv/storage/images/dummy.png'
                "
                :imageFromUrl="true"
                :duration="`Tayang ${$moment(+item.startDate)
                  .local()
                  .format('DD MMM YYYY')}`"
                :category="item.interest"
                :channel="item.channel"
                :title="item.title"
                :isAdvertorial="item.isAdvertorial"
                :isText="item.isText"
                class="card-video-populer"
              />
            </transition-group>
            <div
              class="button-show-more"
              v-if="
                $store.state.live.vod.items.length !==
                $store.state.live.vod.totalItem
              "
            >
              <span @click="nextPopuler">{{
                $store.state.live.vod.loadingShowMore
                  ? "LOADING"
                  : "LIHAT LEBIH BANYAK"
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- START ADS -->
    <LivestreamAds />
    <!-- END ADS -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "../../config";
import ModalShare from "/components/modal/Share";
import CardEight from "/components/cards/CardEight.vue";
import LivestreamAds from "/components/ads/livestream/LivestreamAds.vue";
import * as playerStreaming from "../../static/plugins/jwplayer-8.24.0/channel-embed";
import ModalPermission from "@/components/modal/ModalPermission";
import ModalLiveChat from "@/components/modal/LiveChat";
import ModalLivePolling from "@/components/modal/LivePolling";
import TitleSection from "@/components/TitleSection";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import LiveChatting from "@/components/live/Chatting";
import LivePolling from "@/components/live/Polling";
import ShimmerCardHomeLandscapeSmall from "@/components/shimmer/CardHomeLandscapeSmall";

export default {
  async asyncData({ error, store, params, $axios, route }) {
    store.commit("config/setLoading", true);
    let detail = await $axios
      .$get(`${config.CORE_SERVICE_API}livestreaming/${params.slug}`)
      .then((response) => {
        store.commit("seo/SET_SEO", {
          title: response.title,
          desc: response.description,
          image: response.thumbnail,
          url: `${config.BASE_URL}${route.fullPath}`,
          path: `${config.BASE_URL}${route.path}`,
        });

        store.commit("config/setLoading", false);

        return response;
      })
      .catch((e) => {
        store.commit("config/setLoading", false);
        error({ statusCode: 404, message: "Page not found" });
      });

    return { detail };
  },
  components: {
    ShimmerCardHomeLandscapeSmall,
    LivePolling,
    LiveChatting,
    Icons,
    Link,
    TitleSection,
    ModalLivePolling,
    ModalLiveChat,
    ModalPermission,
    ModalShare,
    CardEight,
    LivestreamAds,
  },
  head() {
    return this.headReturn;
  },
  data() {
    return {
      noAccess: false,
      tabActive: "LiveChatting",
      detail: null,
      limit: 3,
      pageLive: 1,
      activeDate: this.$moment().format("YYYY-MM-DD"),
      listDates: {
        loading: true,
        items: [],
      },
      dates: [],
      schedules: [],
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  methods: {
    checkRange(startTime, endTime) {
      const compareDate = this.$moment().local();
      const startDateMoment = this.$moment
        .unix(Math.floor(startTime / 1000.0))
        .local();
      const endDateMoment = this.$moment
        .unix(Math.floor(endTime / 1000.0))
        .local();
      return compareDate.isBetween(startDateMoment, endDateMoment);
    },
    getSchedule(param) {
      let data = [];
      this.$store.state.live.schedule.items.map((item) => {
        if (param == Object.keys(item)[0]) {
          data = item[Object.keys(item)[0]];
        }
      });

      this.schedules = data;
    },
    async initData() {
      await this.$store.dispatch("live/getVodLiveStreaming", {
        limit: this.limit,
      });

      await this.$store.dispatch("live/getLiveStreaming", {
        pageLive: this.pageLive,
      });

      await this.$store.dispatch("live/getScheduleLiveStreaming").then(() => {
        this.getSchedule(this.$moment().format("YYYY-MM-DD"));
      });
    },
    nextPopuler() {
      this.limit = this.limit + 3;
      this.$store.dispatch("live/getVodLiveStreaming", {
        limit: this.limit,
        loadingShowMore: true,
      });
    },
  },
  mounted() {
    const script = document.createElement("script");
    // script.onload = () => {
    //   let player = null;
    // };
    setTimeout(() => {
      if (this.detail.refEventId) {
        this.$axios
          .$get(
            `${config.EVENT_SERVICE_API}events/users-attend/${this.detail.refEventId}`
          )
          .then((res) => {
            this.noAccess = false;
          })
          .catch((e) => {
            this.noAccess = true;
            this.modalPermission.show();
          });
      }

      if (this.detail.embedUrl && !this.detail.channelIdPlayer) {
        const that = this;
        new YT.Player("video-player", {
          videoId: this.detail.embedUrl
            ? this.detail.embedUrl.substr(17, 11)
            : "",
          height: 473,
          playerVars: {
            autoplay: 1,
            rel: 0,
            modestbranding: 1,
          },
        });
      } else {
        if (this.detail.status !== "idle") {
          playerStreaming.setup({
            jwplayer: jwplayer,
            channelId: this.detail.channelIdPlayer,
            mediaId: this.detail.mediaIdPlayer[0],
            container: "video-player",
            height: 473,
          });
        } else {
          let playlists = [];
          this.detail.mediaIdPlayer.map((data, i) => {
            playlists.push({
              mediaid: data,
              file: `https://cdn.jwplayer.com/manifests/${data}.m3u8`,
            });
          });

          player = jwplayer("video-player").setup({
            playlist: playlists.reverse(),
            image: this.detail.thumbnail,
            title: this.detail.title,
            description: this.detail.description,
            aspectratio: "16:9",
            stretching: "uniform",
            width: "100%",
            height: "473",
            ga: {
              gtag: "gtag",
              label: "mediaid",
              trackerName: "",
              trackingobject: "_gaq",
              universalga: "ga",
            },
          });
        }
      }
    }, 50);

    script.type = "text/javascript";
    script.src = `https://cdn.jwplayer.com/libraries/${
      this.detail.status !== "idle" ? "GOujsiS6" : "LulCuIg8"
    }.js`;
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = `https://ssl.p.jwpcdn.com/live/channel-embed.js`;
    document.head.appendChild(script2);

    this.initData();
    // console.log(this.listDates.items);
  },
};
</script>

<style lang="scss" scoped>
.livestream {
  padding: 32px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 0;
    }
    ::v-deep .insert_ads {
      width: 100% !important;
    }
    .primary {
      width: 65%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .chat-polling {
        height: 52px;
        background: #211f26;
        box-shadow: inset 0px 4px 4px rgba(117, 117, 117, 0.1);
        padding: 16px 60px;
        @media only screen and (min-width: 1024px) {
          display: none;
        }
        .chat,
        .polling {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
      .video-description {
        min-height: 283px;
        background-color: #0c0b0d;
        padding: 15px;
        line-height: 10px;
        position: relative;
        .video-category {
          font-size: 10px;
          font-weight: 500;
          color: #f9e400;
          margin-bottom: 15px;
        }
        .video-title {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
        }
        .video-caption {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #e0e0e0;
        }
        .share-wrapper {
          bottom: 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            @media only screen and (max-width: 1024px) {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #e0e0e0;
            margin: 0;
            text-decoration: underline;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }
      }
      .player {
        background: #616161;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1024px) {
          height: 220px;
        }
        .img-fluid {
          width: 100%;
        }
        svg {
          margin-bottom: 32px;
          @media only screen and (max-width: 1024px) {
            width: 60px;
            height: 60px;
            margin-bottom: 24px;
          }
        }
        .title {
          color: #fafafa;
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 4px;
          }
        }
        .desc {
          color: #e0e0e0;
          font-size: 16px;
          margin: 0;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
      .schedule {
        background: #fafafa;
        padding: 16px 16px 24px;
        .title {
          @media only screen and (max-width: 1024px) {
            margin-bottom: 16px;
          }
        }
        .title-date {
          font-size: 16px;
          font-weight: 500;
          color: #0c0b0d;
          margin: 20px 12px 12px 0;
        }
        .date-wrapper {
          display: flex;
          border-bottom: 1px solid lightgray;
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            overflow-x: auto;
          }
          .box {
            display: flex;
            flex-direction: column;
            padding-right: 30px;
            padding-bottom: 12px;
            cursor: pointer;
            border-bottom: 1px solid transparent;
            &.active {
              border-color: #4a25aa;
              .date {
                color: #4a25aa;
              }
              .day {
                color: #4a25aa;
              }
            }
            .date {
              font-size: 16px;
              font-weight: 500;
              color: #616161;
            }
            .day {
              font-size: 12px;
              color: #757575;
            }
          }
        }
        .detail-schedule {
          &.empty {
            padding: 32px 0;
          }
          .fade-enter-active {
            transition: opacity 0.5s;
          }
          .fade-enter /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
          }
          .list-livestream {
            .schedules {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid lightgray;
              padding: 16px 0;
              position: relative;
              @media only screen and (max-width: 1024px) {
                flex-direction: column;
              }
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                border: none;
                padding-bottom: 0;
              }
              .primary {
                width: 20%;
                @media only screen and (max-width: 1024px) {
                  width: 100%;
                  margin-bottom: 8px;
                }
                .time {
                  font-size: 12px;
                  font-weight: 500;
                  color: #757575;
                }
                a.playing {
                  position: relative;
                  background: linear-gradient(
                    264.64deg,
                    #442da2 55.88%,
                    #783179 95.71%
                  );
                  padding: 8px 6px;
                  text-align: center;
                  height: 32px;
                  width: 118px;
                  border-radius: 4px;
                  font-size: 10px;
                  font-weight: 700;
                  color: #ffffff;
                  display: inline-block;
                  @media only screen and (max-width: 1024px) {
                    position: absolute;
                    right: 10px;
                    top: 0px;
                  }
                  &.disabled {
                    background: #e0e0e0 !important;
                    color: #9e9e9e;
                    &:hover {
                      color: #9e9e9e !important;
                    }
                    &::after {
                      display: none !important;
                    }
                  }
                  &::after {
                    position: absolute;
                    margin-top: 3px;
                    margin-left: 3px;
                    background-image: url("../../assets/img/arrow-right.png");
                    background-repeat: no-repeat;
                    background-size: 6px;
                    display: inline-block;
                    width: 10px;
                    height: 20px;
                    content: "";
                  }
                  &:hover {
                    color: #ffffff !important;
                  }
                }
              }
              .secondary {
                width: 80%;
                @media only screen and (max-width: 1024px) {
                  width: 100%;
                }
                .channel {
                  font-size: 12px;
                  font-weight: 500;
                  color: #4a25aa;
                }
                .title {
                  font-size: 16px;
                  font-weight: 500;
                  color: #0c0b0d;
                  @media only screen and (max-width: 1024px) {
                    width: 70%;
                  }
                }
                .desc {
                  font-size: 14px;
                  color: #757575;
                  ::v-deep p {
                    margin: 0;
                  }
                }
              }
            }
          }
          .no-schedule {
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
              width: 35px;
              height: auto;
              margin-bottom: 16px;
            }
            .title-no-schedule {
              font-size: 14px;
              font-weight: 500;
              color: #0c0b0d;
              margin-bottom: 8px;
              text-align: center;
            }
            .desc-no-schedule {
              font-size: 12px;
              color: #333333;
              text-align: center;
              margin: 0;
              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .secondary {
      width: 32%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .live-chat {
        @media only screen and (max-width: 1024px) {
          display: none;
        }
        .button-wrapper {
          display: flex;
          justify-content: space-between;
          background: #fafafa;
          margin-bottom: 8px;
          .box {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 0;
            cursor: pointer;
            svg {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
            .text {
              margin: 0;
              font-size: 16px;
              font-weight: 700;
              color: #9e9e9e;
              &.success {
                color: #4a25aa;
              }
            }
          }
        }
      }

      .playlist-video {
        width: 30%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding-top: 24px;
        }
        &__populer {
          margin-top: 32px;
          @media only screen and (max-width: 1024px) {
            padding: 0 20px;
          }
          .header {
            margin-bottom: 24px;
          }
          .body {
            .list-enter-active {
              transition: all 1s;
            }
            .list-enter /* .list-leave-active below version 2.1.8 */ {
              opacity: 0;
            }
            .list-video-populer {
              margin-bottom: 24px;
              ::v-deep .card-eight {
                &:first-child {
                  padding-top: 0;
                }
              }
              .card-video-populer {
                width: 100%;
                &:nth-child(6) {
                  padding-top: 0;
                }
              }
            }
            .button-show-more {
              text-align: center;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
