var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container detail-karir"},[_c('section',{attrs:{"id":"breadcrumbs"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"breadcrumbs"},[_c('Link',{attrs:{"to":'/'}},[_vm._v("HOME\n        "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{attrs:{"to":{
          name: 'karir',
        }}},[_vm._v("KARIR\n        "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Link',{staticClass:"active",attrs:{"to":"#"}},[_vm._v(_vm._s(_vm.detail.data.title)+"\n        "),_c('Icons',{attrs:{"name":"arrow-right"}})],1)],1)])]),_vm._v(" "),_c('section',{staticClass:"karir"},[_c('div',{staticClass:"content-wrapper"},[_c('p',{staticClass:"division"},[_vm._v(_vm._s(_vm.detail.data.team))]),_vm._v(" "),_c('h1',{staticClass:"position"},[_vm._v(_vm._s(_vm.detail.data.title))]),_vm._v(" "),_c('div',{staticClass:"box"},[_c('div',{staticClass:"item status"},[_vm._v(_vm._s(_vm.detail.data.typeOfWork))]),_vm._v(" "),_c('div',{staticClass:"item work-type"},[_c('Icons',{attrs:{"name":"location","color":"#ffffff"}}),_vm._v(_vm._s(_vm.detail.data.location)+"\n        ")],1)]),_vm._v(" "),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.detail.data.description)}})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.karirs.items && _vm.karirs.items.length > 0),expression:"karirs.items && karirs.items.length > 0"}],staticClass:"recommend-careers row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"position-relative"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperOptions),expression:"swiperOptions",arg:"mySwiper"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.karirs.items),function(item){return _c('Link',{key:item.id,staticClass:"card-karir swiper-slide",attrs:{"to":{
              name: 'karir-slug',
              params: {
                slug: item.slug,
              },
            }}},[_c('div',{staticClass:"content-wrapper"},[_c('p',{staticClass:"division"},[_vm._v(_vm._s(item.team))]),_vm._v(" "),_c('h1',{staticClass:"position"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('div',{staticClass:"box"},[_c('div',{staticClass:"item status"},[_vm._v(_vm._s(item.typeOfWork))]),_vm._v(" "),_c('div',{staticClass:"item work-type"},[_c('Icons',{attrs:{"name":"location","color":"#ffffff"}}),_vm._v(_vm._s(item.location)+"\n                ")],1)])])])}),1),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-left prev"},[_c('Icons',{staticClass:"arrow",attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"shadow-arrow shadow-right next"},[_c('Icons',{staticClass:"arrow",attrs:{"name":"arrow-right"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-to"},[_vm._v("\n      kirimkan lamaran Anda ke "),_c('strong',[_vm._v("rekrutmen@narasi.tv")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"title-wrapper"},[_c('div',{staticClass:"titleContent"},[_c('p',{staticClass:"title m-0"},[_vm._v("KARIR LAINNYA")])])])])
}]

export { render, staticRenderFns }