<template>
  <div class="container detail-karir">
    <section id="breadcrumbs">
      <div class="container">
        <div class="breadcrumbs">
          <Link :to="'/'">HOME
          <Icons name="arrow-right" />
          </Link>
          <Link :to="{
            name: 'karir',
          }">KARIR
          <Icons name="arrow-right" />
          </Link>
          <Link to="#" class="active">{{ detail.data.title }}
          <Icons name="arrow-right" />
          </Link>
        </div>
      </div>
    </section>
    <section class="karir">
      <div class="content-wrapper">
        <p class="division">{{ detail.data.team }}</p>
        <h1 class="position">{{ detail.data.title }}</h1>
        <div class="box">
          <div class="item status">{{ detail.data.typeOfWork }}</div>
          <div class="item work-type">
            <Icons name="location" color="#ffffff" />{{ detail.data.location }}
          </div>
        </div>
        <p class="description" v-html="detail.data.description"></p>
      </div>
      <div class="send-to">
        kirimkan lamaran Anda ke <strong>rekrutmen@narasi.tv</strong>
      </div>
    </section>

    <section class="recommend-careers row" v-show="karirs.items && karirs.items.length > 0">
      <div class="container">
        <div class="title-wrapper">
          <div class="titleContent">
            <p class="title m-0">KARIR LAINNYA</p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="position-relative">
          <div v-swiper:mySwiper="swiperOptions">
            <div class="swiper-wrapper">
              <Link :to="{
                name: 'karir-slug',
                params: {
                  slug: item.slug,
                },
              }" class="card-karir swiper-slide" v-for="item in karirs.items" :key="item.id">
              <div class="content-wrapper">
                <p class="division">{{ item.team }}</p>
                <h1 class="position">{{ item.title }}</h1>
                <div class="box">
                  <div class="item status">{{ item.typeOfWork }}</div>
                  <div class="item work-type">
                    <Icons name="location" color="#ffffff" />{{
                      item.location
                    }}
                  </div>
                </div>
              </div>
              </Link>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="shadow-arrow shadow-left prev">
            <Icons name="arrow-left" class="arrow" />
          </div>
          <div class="shadow-arrow shadow-right next">
            <Icons name="arrow-right" class="arrow" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

export default {
  async asyncData({ error, params, store, $axios, route }) {
    let detail = await $axios
      .$get(`${config.CORE_SERVICE_API}careers/${params.slug}`)
      .then((response) => {
        store.commit("seo/SET_SEO", {
          title: `Lowongan Pekerjaan ${response.data.title}`,
          desc: `Lowongan Pekerjaan ${response.data.typeOfWork} ${response.data.title} team ${response.data.team}`,
          image: "https://narasi.tv/new-icon.png",
          url: `${config.BASE_URL}${route.fullPath}`,
          path: `${config.BASE_URL}${route.path}`,
        });

        return response;
      })
      .catch((e) => {
        error({ statusCode: 404, message: "Page not found" });
      });

    return { detail };

  },
  components: {
    Link,
    Icons
  },
  data() {
    return {
      swiperOptions: {
        freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        // autoplay: {
        //   delay: 5000,
        // },
        navigation: {
          prevEl: ".prev",
          nextEl: ".next",
        },
        breakpoints: {
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      karirs: (state) => {
        return state.karir.karirs;
      },
      karir: (state) => {
        return state.karir.karir.items;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("karir/getKarirs");
      // await this.$store.dispatch("karir/getKarir", this.$route.params.slug);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  &.detail-karir {
    padding-top: 48px;
    padding-bottom: 48px;

    @media only screen and (max-width: 1024px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  #breadcrumbs {
    margin-bottom: 24px;

    @media only screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }

    .container {
      .breadcrumbs {
        display: flex;
        align-items: center;

        a {
          font-size: 14px;
          color: #616161;
          display: flex;
          align-items: center;

          svg {
            width: 18px;
            height: auto;
            margin: 0 10px;
          }

          &.active {
            color: #34128c;
            font-weight: 700;
          }

          &:hover {
            color: #616161 !important;
          }

          &:last-child {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }

  .karir {
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 48px;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 1024px) {
      padding: 20px;
    }

    .content-wrapper {
      .division {
        font-size: 16px;
        color: #0c0b0d;
      }

      .position {
        font-size: 24px;
        color: #0c0b0d;
        margin-bottom: 10px;
      }

      .box {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .item {
          display: flex;
          align-items: center;
          font-size: 14px;
          background: #0c0b0d;
          color: #ffffff;
          margin: 0;
          padding: 5px 10px;
          border-radius: 15px;
          margin-right: 5px;

          svg {
            margin-right: 5px;
          }
        }
      }

      .description {
        font-size: 14px;
        color: #757575;
        line-height: 200%;
      }
    }

    .send-to {
      font-size: 16px;
      text-align: center;

      strong {
        color: #ffe900;
        background: #4a25aa;
        padding: 6px;
        border-radius: 4px;
      }
    }
  }

  .recommend-careers {
    position: relative;

    .container {
      .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @media only screen and (max-width: 1024px) {
          margin-bottom: 16px;
        }

        .titleContent {
          @media only screen and (max-width: 1024px) {
            line-height: 16px;
          }

          .title {
            color: #4a25aa;
            font-size: 20px;
            font-weight: 700;
            padding-left: 30px;
            border-left: 10px solid #ffe900;

            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              padding-left: 10px;
              border-width: 5px;
            }
          }
        }

        .see-all {
          font-size: 16px;
          font-weight: bold;
          text-decoration: underline !important;

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      .card-karir {
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width: 1024px) {
          padding: 20px;
        }

        .content-wrapper {
          .division {
            font-size: 16px;
            color: #616161;
            margin-bottom: 10px;
          }

          .position {
            font-size: 24px;
            color: #0c0b0d;
            margin-bottom: 10px;
          }

          .box {
            display: flex;
            align-items: center;

            .item {
              display: flex;
              align-items: center;
              font-size: 14px;
              background: #0c0b0d;
              color: #ffffff;
              margin: 0;
              padding: 5px 10px;
              border-radius: 15px;
              margin-right: 5px;

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .shadow-arrow {
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
        padding: 0;
        background: rgb(0, 0, 0);
        z-index: 2;
        opacity: 1;
        transition: ease-in-out 0.5s;

        @media only screen and (max-width: 1024px) {
          display: none;
        }

        svg {
          pointer-events: visible;
        }

        &.shadow-left {
          left: 0;
          background: linear-gradient(to left,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1));
        }

        &.shadow-right {
          right: 0;
          background: linear-gradient(to right,
              hsla(0, 0%, 100%, 0),
              rgba(241, 241, 241, 1));
        }

        &.swiper-button-disabled {
          opacity: 0;
          z-index: 0;
        }
      }

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: auto;

        @media only screen and (max-width: 1024px) {
          display: none;
        }

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }

        &.hide {
          display: none;
        }
      }

      .swiper-container {
        @media only screen and (max-width: 1024px) {
          padding: 20px;
          margin: -20px;
        }

        .swiper-wrapper {
          height: fit-content !important;
        }
      }
    }
  }
}
</style>
