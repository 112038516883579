<template></template>

<script>
import config from "/config";

export default {
  async asyncData({ error, app, params, query, redirect }) {
    try {
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}interests/v2/${query.type}`
      );
      if (query.type == "uncategorized") {
        redirect(
          301,
          query.ref
            ? `/${response.data.parent.slug}/semua?ref=${query.ref}`
            : `/${response.data.parent.slug}/semua`
        );
      } else if (response.status === 200) {
        redirect(
          301,
          query.ref
            ? `/${response.data.parent.slug}/${query.type}?ref=${query.ref}`
            : `/${response.data.parent.slug}/${query.type}`
        );
      }
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
};
</script>
