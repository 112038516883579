<template>
  <div class="invitation-box">
    <ModalLogin :redirect="$route.path" @registeredModal="modalLogin = $event" />
    <div class="card-invite">

      <h1 class="title">{{ token.isValid ? 'Anda telah diundang untuk mengikuti' : 'Anda sekarang mengikuti'}} {{ token.type === 'Academy' ? 'kelas' : 'event' }}</h1>
      <h2 class="sub-title">{{ detail?.data.title }}</h2>
      <template v-if="token.isValid">
        <div class="text-center">
          <i class="material-icons">schedule</i>
          <button type="button" v-if="$store.state.auth.loggedIn" @click="acceptInvitation" class="btn btn-primary">TERIMA
            UNDANGAN</button>
          <button type="button" v-else @click="modalLogin.show()" class="btn btn-primary">LOGIN & DAFTAR</button>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <i class="material-icons">task_alt</i>
          <nuxt-link :to="token.type === 'Academy' ? `/academy/detail/${detail?.data.slug}`: `/event/${detail?.data.organizerSlug}/${detail?.data.slug}/checkout`" v-if="$store.state.auth.loggedIn" class="btn btn-primary">MASUK {{token.type === 'Academy' ? 'KELAS' : 'EVENT'}}</nuxt-link>
          <button type="button" v-else @click="modalLogin.show()" class="btn btn-primary">LOGIN & DAFTAR</button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import config from '../../config'
import ModalLogin from "@/components/modal/Login"

export default {
  components: {
    ModalLogin,
  },
  async asyncData({ app, $axios, params, error }) {
    const token = await $axios.$get(`${config.PAYMENT_SERVICE_API}api/payments/check-invitation/${params.token}`).then((res) => {
      return res;
    }).catch((e) => {
      error({ statusCode: 500, message: "Interval server error" });
    })

    if (!token) {
      return error({ statusCode: 400, message: "Token tidak valid" });
    }

    let endpoint = `${config.EVENT_SERVICE_API}events/detail/${token.referency}`;
    if (token.type === 'Academy') {
      endpoint = `${config.ACADEMY_SERVICE_API}class/${token.referency}`;
    }

    const detail = await $axios.$get(endpoint).then((res) => {
      return res;
    }).catch((e) => {
      error({ statusCode: 500, message: "Interval server error" });
    })

    return { token, detail }
  },
  head() {
    return {
      title: `Invitation ${this.token.type} Narasi TV`
    }
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    next();
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.modalLogin.hide();
      next(true);
    });
    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  methods: {
    async acceptInvitation(event) {
      await this.$axios.$post(`${config.PAYMENT_SERVICE_API}api/payments/accept-invitation/${this.$route.params.token}`).then((res) => {
        this.token = res;
      }).catch((e) => {
        this.$toast.error("Mohon maaf undangan tidak valid...")
      })
    }
  }
}
</script>

<style lang="scss">
.invitation-box {
  min-height: 65vh;
  position: relative;
  .material-icons {
    margin-top: 20px;
    font-size: 80px;
    color: #4a25aa;
    display: block;
  }

  .card-invite {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
    @media only screen and (max-width: 1024px) {
      width: 350px;
      max-width: 400px;
    }

    .title {
      text-align: center;
      font-size: 24px;
    }

    .sub-title {
      text-align: center;
      font-size: 18px;
    }

    .btn-primary {
      margin-top: 24px;
      height: 43px;
      border-radius: 8px;

      &:hover {
        color: #ffffff !important;
      }
    }
  }
}
</style>
