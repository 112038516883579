<template></template>

<script>
import config from "/config";

export default {
  async asyncData({ error, app, params, query, redirect }) {
    try {
      if (query.type == "uncategorized") {
        redirect(301, query.ref ? `/news?ref=${query.ref}` : `/news`);
      } else if (query.type == "news") {
        redirect(301, query.ref ? `/news?ref=${query.ref}` : `/news`);
      } else if (query.type == "tech-edu") {
        redirect(301, query.ref ? `/tech-edu?ref=${query.ref}` : `/tech-edu`);
      } else if (query.type == "lifestyle") {
        redirect(301, query.ref ? `/lifestyle?ref=${query.ref}` : `/lifestyle`);
      } else if (query.type == "hype") {
        redirect(301, query.ref ? `/hype?ref=${query.ref}` : `/hype`);
      } else if (query.type) {
        const response = await app.$axios.$get(
          `${config.CORE_SERVICE_API}interests/v2/${query.type}`
        );
        redirect(
          301,
          query.ref
            ? `/${response.data.parent.slug}/${query.type}?ref=${query.ref}`
            : `/${response.data.parent.slug}/${query.type}`
        );
      }
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
};
</script>
