var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"livestream"},[_c('ModalShare',{on:{"registeredModal":function($event){_vm.modalShare = $event}}}),_vm._v(" "),_c('ModalPermission',{attrs:{"data":_vm.detail},on:{"registeredModal":function($event){_vm.modalPermission = $event}}}),_vm._v(" "),_c('client-only',[_c('ModalLiveChat',{attrs:{"data":_vm.detail},on:{"registeredModal":function($event){_vm.modalLiveChat = $event}}}),_vm._v(" "),_c('ModalLivePolling',{attrs:{"data":_vm.detail},on:{"registeredModal":function($event){_vm.modalLivePolling = $event}}})],1),_vm._v(" "),_c('div',{staticClass:"container",style:(_vm.noAccess ? 'filter: blur(8px); -webkit-filter: blur(8px);' : '')},[_c('div',{staticClass:"primary"},[_c('client-only',[_c('div',{staticClass:"player"},[(
              _vm.noAccess ||
              (_vm.detail.mediaIdPlayer.length === 0 && !_vm.detail.embedUrl)
            )?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.detail.thumbnail}}):_c('div',{ref:"videoPlayer",staticClass:"ratio ratio-16x9",attrs:{"id":"video-player"}})])]),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"video-description"},[_c('div',{staticClass:"video-category"},[_vm._v(_vm._s(_vm.detail.channel.title))]),_vm._v(" "),_c('h1',{staticClass:"video-title"},[_vm._v(_vm._s(_vm.detail.title))]),_vm._v(" "),_c('p',{staticClass:"video-caption",domProps:{"innerHTML":_vm._s(_vm.detail.description)}}),_vm._v(" "),_c('div',{staticClass:"share-wrapper",on:{"click":function($event){return _vm.modalShare.show()}}},[_c('Icons',{staticClass:"icon",attrs:{"name":"bagikan","color":"#E0E0E0"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Bagikan")])],1)])]),_vm._v(" "),_c('div',{staticClass:"chat-polling"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"chat",on:{"click":function($event){return _vm.modalLiveChat.show()}}},[_c('em',{staticClass:"material-icons mx-2 text-white"},[_vm._v("question_answer")]),_vm._v(" "),_c('span',{staticClass:"text-white fw-bold fs-14 btn-live-chat"},[_vm._v("LIVE CHAT")])]),_vm._v(" "),_c('div',{staticClass:"polling",on:{"click":function($event){return _vm.modalLivePolling.show()}}},[_c('em',{staticClass:"material-icons mx-2 text-white"},[_vm._v("insert_chart")]),_vm._v(" "),_c('span',{staticClass:"text-white fw-bold fs-14 btn-live-chat"},[_vm._v("POLLING")])])])]),_vm._v(" "),_c('div',{staticClass:"schedule"},[_c('TitleSection',{attrs:{"title":"JADWAL PEKAN INI","size":"medium"}}),_vm._v(" "),_c('h2',{staticClass:"title-date"},[_vm._v(_vm._s(_vm.$moment().format("MMMM Y")))]),_vm._v(" "),_c('div',{staticClass:"date-wrapper"},_vm._l((_vm.$store.state.live.schedule.items),function(date,index){return _c('div',{key:index + '-date',staticClass:"box",class:{ active: _vm.activeDate === Object.keys(date)[0] },on:{"click":function($event){(_vm.activeDate = Object.keys(date)[0]),
                _vm.getSchedule(Object.keys(date)[0])}}},[_c('div',{staticClass:"date"},[_vm._v("\n              "+_vm._s(_vm.$moment(Object.keys(date)[0]).format("DD"))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"day"},[_vm._v("\n              "+_vm._s(_vm.$moment(Object.keys(date)[0]).format("dddd"))+"\n            ")])])}),0),_vm._v(" "),_c('div',{staticClass:"detail-schedule",class:{ empty: _vm.schedules.length === 0 }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.schedules.length > 0)?_c('div',{key:"save",staticClass:"list-livestream"},_vm._l((_vm.schedules),function(data,index){return _c('div',{key:index + '-schedule',staticClass:"schedules"},[_c('div',{staticClass:"secondary"},[_c('div',{staticClass:"time"},[_vm._v("\n                    "+_vm._s(_vm.$moment
                        .unix(Math.floor(+data.startTime / 1000.0))
                        .utc()
                        .format("HH:mm"))+"\n                    WIB\n                  ")]),_vm._v(" "),_c('div',{staticClass:"channel"},[_vm._v(_vm._s(data.channel.title))]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n                    "+_vm._s(data.title)+"\n                  ")])]),_vm._v(" "),(_vm.checkRange(data.startTime, data.endTime))?_c('div',{staticClass:"primary"},[(data.slug === _vm.$route.params.slug)?_c('Link',{staticClass:"playing disabled",attrs:{"to":`/live/${data.slug}`}},[_vm._v("SEDANG DILIHAT")]):_c('Link',{staticClass:"playing",attrs:{"to":`/live/${data.slug}`}},[_vm._v("LIHAT TAYANGAN")])],1):_vm._e()])}),0):_c('div',{key:"save_",staticClass:"no-schedule"},[_c('Icons',{attrs:{"name":"no-schedule"}}),_vm._v(" "),_c('p',{staticClass:"title-no-schedule"},[_vm._v("\n                Tidak ada jadwal live streaming di tanggal ini\n              ")]),_vm._v(" "),_c('p',{staticClass:"desc-no-schedule"},[_vm._v("\n                Silakan cek tanggal lainnya untuk program live streaming\n                "),_c('br',{staticClass:"for-mobile"}),_vm._v("yang bisa kamu saksikan\n              ")])],1)])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"secondary"},[_c('div',{staticClass:"live-chat"},[_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"box",on:{"click":function($event){_vm.tabActive = 'LiveChatting'}}},[_c('Icons',{attrs:{"name":_vm.tabActive === 'LiveChatting'
                  ? 'live-chat-enable'
                  : 'live-chat-disable'}}),_vm._v(" "),_c('p',{staticClass:"text",class:_vm.tabActive === 'LiveChatting' ? 'success' : ''},[_vm._v("\n              LIVE CHAT\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"box",on:{"click":function($event){_vm.tabActive = 'LivePolling'}}},[_c('Icons',{attrs:{"name":_vm.tabActive === 'LivePolling'
                  ? 'polling-enable'
                  : 'polling-disable'}}),_vm._v(" "),_c('p',{staticClass:"text",class:_vm.tabActive === 'LivePolling' ? 'success' : ''},[_vm._v("\n              POLLING\n            ")])],1)]),_vm._v(" "),(_vm.tabActive === 'LiveChatting')?_c('LiveChatting',{attrs:{"detail":_vm.detail,"referencyId":_vm.$route.params.slug}}):_c('LivePolling',{attrs:{"detail":_vm.detail,"referencyId":_vm.$route.params.slug}})],1),_vm._v(" "),(
          _vm.$store.state.live.live.loading ||
          _vm.$store.state.live.live.items.length > 1
        )?_c('div',{staticClass:"playlist-video__populer"},[_c('div',{staticClass:"header"},[_c('TitleSection',{staticClass:"header__title",attrs:{"title":"STREAMING LAINNYA","size":"medium"}})],1),_vm._v(" "),_c('div',{staticClass:"body"},[_c('transition-group',{staticClass:"list-video-populer",attrs:{"name":"list","tag":"div"}},[_vm._l((5),function(item,idx){return _c('ShimmerCardHomeLandscapeSmall',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.live.live.loading),expression:"$store.state.live.live.loading"}],key:`live-${idx}`})}),_vm._v(" "),_vm._l((_vm.$store.state.live.live.items),function(item){return _c('CardEight',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.live.live.loading),expression:"!$store.state.live.live.loading"}],key:item.id,staticClass:"card-video-populer",attrs:{"to":`/live/${item.slug}`,"time":{
                startTime: item.startDate,
                endTime: item.endDate,
              },"isAdvertorial":item.isAdvertorial,"isText":item.isText,"image":item.thumbnail
                  ? item.thumbnail
                  : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":'LIVE',"category":item.interest,"channel":item.channel,"title":item.title}})})],2)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"playlist-video__populer"},[_c('div',{staticClass:"header"},[_c('TitleSection',{staticClass:"header__title",attrs:{"title":"STREAMING SEBELUMNYA","size":"medium"}})],1),_vm._v(" "),_c('div',{staticClass:"body"},[_c('transition-group',{staticClass:"list-video-populer",attrs:{"name":"list","tag":"div"}},[_vm._l((5),function(item,idx){return _c('ShimmerCardHomeLandscapeSmall',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.live.vod.loading),expression:"$store.state.live.vod.loading"}],key:`vod-${idx}`})}),_vm._v(" "),_vm._l((_vm.$store.state.live.vod.items),function(item){return _c('CardEight',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.live.vod.loading),expression:"!$store.state.live.vod.loading"}],key:item.id,staticClass:"card-video-populer",attrs:{"to":`/live/${item.slug}`,"image":item.thumbnail
                  ? item.thumbnail
                  : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":`Tayang ${_vm.$moment(+item.startDate)
                .local()
                .format('DD MMM YYYY')}`,"category":item.interest,"channel":item.channel,"title":item.title,"isAdvertorial":item.isAdvertorial,"isText":item.isText}})})],2),_vm._v(" "),(
              _vm.$store.state.live.vod.items.length !==
              _vm.$store.state.live.vod.totalItem
            )?_c('div',{staticClass:"button-show-more"},[_c('span',{on:{"click":_vm.nextPopuler}},[_vm._v(_vm._s(_vm.$store.state.live.vod.loadingShowMore
                ? "LOADING"
                : "LIHAT LEBIH BANYAK"))])]):_vm._e()],1)])])]),_vm._v(" "),_c('LivestreamAds')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }