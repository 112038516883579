<template>
  <div class="karir">
    <section class="banner">
      <ImageResponsiveBanner
        imageUrl="https://narasi.tv/storage/images/banner-karir.png"
        imageUrlMobile="https://narasi.tv/storage/images/banner-karir.png"
        :fromUrl="true"
        width="1440"
        height="350"
        widthMobile="720"
        heightMobile="175"
      />
    </section>
    <section class="content">
      <div class="filter">
        <div class="container">
          <div class="box input-search">
            <form v-on:submit.prevent="initData()">
              <input
                type="search"
                class="search"
                name="q"
                placeholder="Ketik pekerjaan impianmu"
                v-model="valueSearch"
              />
              <span aria-label="Search">
                <Icons name="search" class="search-icon" />
              </span>
            </form>
          </div>
          <div class="box input-departemen">
            <multiselect
              v-model="departemenSelected"
              :options="teams.items"
              ref="multiselectDepartemen"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pilih Departemen"
            >
            </multiselect>
          </div>
          <div class="box input-jenis-pekerjaan">
            <multiselect
              v-model="jenisPekerjaanSelected"
              :options="TOW.items"
              ref="multiselectJenisPekerjaan"
              :multiple="true"
              :searchable="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pilih Jenis Pekerjaan"
            >
            </multiselect>
          </div>
          <div class="box button-search" @click="initData()">Search</div>
        </div>
      </div>
      <div class="container container-card">
        <div
          class="card-wrapper"
          v-if="karirs.items && karirs.items.length > 0"
        >
          <Link
            :to="{
              name: 'karir-slug',
              params: {
                slug: item.slug,
              },
            }"
            class="card-karir"
            v-for="item in karirs.items"
            :key="item.id"
          >
            <div class="content-wrapper">
              <p class="division">{{ item.team }}</p>
              <h1 class="position">{{ item.title }}</h1>
              <div class="box">
                <div class="item status">{{ item.typeOfWork }}</div>
                <div class="item work-type">
                  <Icons name="location" color="#ffffff" />{{ item.location }}
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div class="no-karir" v-else>
          <ImageResponsive imageUrl="no-karir.png" />
          <div class="copy-wrapper">
            <h2>Posisi yang kamu cari saat ini <br />sedang tidak tersedia</h2>
            <p>Silakan cari posisi pekerjaan lainnya</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import ImageResponsiveBanner from "@/components/ImageResponsiveBanner";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

export default {
  async asyncData({ app, params, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Karir Narasi",
        desc: "Indonesia tak tersusun dari batas peta, tapi gerak dan peran kaum muda.",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    ImageResponsive,
    ImageResponsiveBanner,
    Icons,
    Link,
  },
  data() {
    return {
      departemenSelected: null,
      jenisPekerjaanSelected: null,
      valueDepartemen: "",
      valueJenisPekerjaan: "",
      valueSearch: "",
      searchData: "",
    };
  },
  head() {
    return this.headReturn;
  },
  methods: {
    async initData() {
      await this.$store.dispatch("karir/getKarirs", {
        search: this.valueSearch,
        typeOfWork: this.valueJenisPekerjaan,
        departemen: this.valueDepartemen,
      });
    },
    closeEventHandler() {
      this.$refs.multiselect.isOpen = true;
      this.$refs.multiselect.deactivate();
    },
    openEventHandler() {
      this.$refs.multiselect.isOpen = true;
      this.$refs.multiselect.activate();
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      karirs: (state) => {
        return state.karir.karirs;
      },
      TOW: (state) => {
        return state.karir.tow;
      },
      teams: (state) => {
        return state.karir.teams;
      },
    }),
  },
  watch: {
    departemenSelected(newValue, oldValue) {
      let res = newValue
        .map(function (elem) {
          return elem;
        })
        .join(",");
      this.valueDepartemen = res;
    },
    jenisPekerjaanSelected(newValue, oldValue) {
      let res = newValue
        .map(function (elem) {
          return elem;
        })
        .join(",");
      this.valueJenisPekerjaan = res;
    },
  },
  mounted() {
    this.initData();
    this.$store.dispatch("karir/getTOW");
    this.$store.dispatch("karir/getTeams");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep .multiselect {
  &__tags-wrap {
    @media only screen and (min-width: 1000px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    &::-webkit-scrollbar {
      @media only screen and (min-width: 1000px) {
        display: none;
      }
    }
  }
  &__tag {
    @media only screen and (min-width: 1000px) {
      overflow: initial;
    }
  }
  &__tags {
    background-color: #f1f1f1;
    @media only screen and (max-width: 1024px) {
      background-color: #ffffff;
    }
  }
  &__option {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
.karir {
  .banner {
    .image {
      width: 100%;
      height: auto;
    }
  }
  .content {
    .filter {
      padding: 20px 0;
      background: #ffffff;
      @media only screen and (max-width: 1024px) {
        background: unset;
      }
      .container {
        display: flex;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          padding: 0 20px;
        }
        .box {
          margin: 0 10px;
          @media only screen and (max-width: 1024px) {
            width: 100% !important;
            margin: 0;
            margin-bottom: 8px;
          }
          &.input-search {
            width: 40%;
            position: relative;
            @media only screen and (max-width: 1024px) {
              margin-right: 20px;
            }
            &.custSearchWrapper {
              @media only screen and (max-width: 1024px) {
                margin: 0;
                width: 100%;
              }
            }
            .close-search {
              @media only screen and (max-width: 1024px) {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
              }
            }
            form {
              position: relative;
              .search {
                padding: 10px 10px 10px 35px;
                background-color: #f1f1f1;
                width: 100%;
                border-radius: 5px;
                box-shadow: none !important;
                outline: none !important;
                color: black;
                border: 1px solid #e0e0e0;
                @media only screen and (max-width: 1024px) {
                  background-color: #ffffff;
                }
                &.custInput {
                  @media only screen and (max-width: 1024px) {
                    width: 90%;
                  }
                  &.active {
                    border-radius: 5px 5px 0 0;
                  }
                }
                &::-webkit-search-cancel-button {
                  -webkit-appearance: none;
                  height: 20px;
                  width: 20px;
                  border-radius: 10px;
                  background: url("~@/assets/img/x-circle.svg") no-repeat 50%
                    50%;
                  filter: invert(8%) sepia(100%) saturate(6481%)
                    hue-rotate(246deg) brightness(102%) contrast(0%);
                }

                &::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: #9e9e9e;
                  opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #9e9e9e;
                }

                &::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #9e9e9e;
                }
                &.active {
                  border-radius: 5px 5px 0 0;
                }
              }
            }
            .search-icon {
              position: absolute;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
              @media only screen and (max-width: 1024px) {
                height: 17px;
                width: auto;
              }
              &.custIcon {
                @media only screen and (max-width: 1024px) {
                  position: unset;
                  transform: unset;
                }
              }
            }
          }
          &.input-departemen {
            width: 25%;
          }
          &.input-jenis-pekerjaan {
            width: 25%;
          }
          &.button-search {
            width: 10%;
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
            background-color: #4a25aa;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .container-card {
      padding: 32px 0 48px;
      @media only screen and (max-width: 1024px) {
        padding: 0 20px 48px 20px;
      }
      .no-karir {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 150px auto;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          margin: 0;
        }
        .image {
          width: 200px;
          @media only screen and (max-width: 1024px) {
            width: 160px;
            margin-bottom: 20px;
          }
        }
        .copy-wrapper {
          h2 {
            font-size: 36px;
            font-weight: 700;
            @media only screen and (max-width: 1024px) {
              font-size: 20px;
              text-align: center;
            }
          }
          p {
            font-size: 24px;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
      .card-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        @media only screen and (max-width: 1024px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .card-karir {
          background: #ffffff;
          border-radius: 10px;
          padding: 30px 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media only screen and (max-width: 1024px) {
            padding: 20px;
          }
          .content-wrapper {
            .division {
              font-size: 16px;
              color: #616161;
              margin-bottom: 10px;
            }
            .position {
              font-size: 24px;
              color: #0c0b0d;
              margin-bottom: 10px;
            }
            .box {
              display: flex;
              align-items: center;
              .item {
                display: flex;
                align-items: center;
                font-size: 14px;
                background: #0c0b0d;
                color: #ffffff;
                margin: 0;
                padding: 5px 10px;
                border-radius: 15px;
                margin-right: 5px;
                svg {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
